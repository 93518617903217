// extracted by mini-css-extract-plugin
export var cardBg = "TechStacksMobile-module--cardBg--48b22";
export var cards = "TechStacksMobile-module--cards--43935";
export var cardsContent = "TechStacksMobile-module--cardsContent--ff6ac";
export var description = "TechStacksMobile-module--description--6b04a";
export var globalIndusTypeScriptTech = "TechStacksMobile-module--globalIndusTypeScriptTech--a6146";
export var heading = "TechStacksMobile-module--heading--f4c4e";
export var iconssCard = "TechStacksMobile-module--iconssCard--b7d5d";
export var nav = "TechStacksMobile-module--nav--41a67";
export var navItem = "TechStacksMobile-module--nav-item--ac10e";
export var navbarBlock = "TechStacksMobile-module--navbarBlock--acc10";
export var tabData = "TechStacksMobile-module--tabData--73662";
export var tech = "TechStacksMobile-module--tech--70215";
export var techIcon = "TechStacksMobile-module--techIcon--e8317";
export var techImg = "TechStacksMobile-module--techImg--a17dc";
export var technologyIcon = "TechStacksMobile-module--technologyIcon--0f7f1";