// extracted by mini-css-extract-plugin
export var attachment = "TypescriptDeveloperForm-module--attachment--33615";
export var attachmentLabel = "TypescriptDeveloperForm-module--attachmentLabel--8b254";
export var errorTxt = "TypescriptDeveloperForm-module--errorTxt--d871e";
export var fieldColor = "TypescriptDeveloperForm-module--fieldColor--56831";
export var formControl = "TypescriptDeveloperForm-module--form-control--15c4a";
export var formInner = "TypescriptDeveloperForm-module--formInner--2b122";
export var hr = "TypescriptDeveloperForm-module--hr--c30b7";
export var newlinks = "TypescriptDeveloperForm-module--newlinks--5e0c5";
export var query = "TypescriptDeveloperForm-module--query--22b2d";
export var salesFormCheckBoxSize = "TypescriptDeveloperForm-module--salesFormCheckBoxSize--cac88";
export var salesFormRowHeight = "TypescriptDeveloperForm-module--salesFormRowHeight--5bc49";