import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./tabbing.scss"
import * as styles from "./TechStacksTypeScript.module.scss"

const data = [
  {
    title: "Framework",
    tech: [
      {
        name: "NextJs",
        icon: "https://invozone-backend.s3.amazonaws.com/next_js_6c43cde822.svg",
      },
      {
        name: "AlpineJS",
        icon: "https://invozone-backend.s3.amazonaws.com/Alpine_js_3a3dc8e1a2.svg",
      },
      {
        name: "NestJS",
        icon: "https://invozone-backend.s3.amazonaws.com/nest_js_9a21773270.svg",
      },
      {
        name: "MeteorJS",
        icon: "https://invozone-backend.s3.amazonaws.com/meteor_js_8c31ccbb88.svg",
      },
      {
        name: "NuxtJS",
        icon: "https://invozone-backend.s3.amazonaws.com/nuxt_js_21bb7ea476.svg",
      },
      {
        name: "StimulusJS",
        icon: "https://invozone-backend.s3.amazonaws.com/stimulus_2eff872d85.svg",
      },
      {
        name: "RedwoodJS",
        icon: "https://invozone-backend.s3.amazonaws.com/redwood_js_aa7be4f8b7.svg",
      },
    ],
  },
  {
    title: "Libraries",
    tech: [
      {
        name: "EtherJS",
        icon: "https://invozone-backend.s3.amazonaws.com/ethers_js_df78406020.svg",
      },
      {
        name: "BackboneJS",
        icon: "https://invozone-backend.s3.amazonaws.com/backbone_js_e97e7a64e0.svg",
      },
      {
        name: "FlowJS",
        icon: "https://invozone-backend.s3.amazonaws.com/flow_js_7053028d2b.svg",
      },
      {
        name: "D3JS",
        icon: "https://invozone-backend.s3.amazonaws.com/d3_js_fabadda7ff.svg",
      },
      {
        name: "CallKit",
        icon: "https://invozone-backend.s3.amazonaws.com/callkit_420fdf1568.svg",
      },
      {
        name: "Web3JS",
        icon: "https://invozone-backend.s3.amazonaws.com/web3_js_5343b9f853.svg",
      },
      {
        name: "InertiaJS",
        icon: "https://invozone-backend.s3.amazonaws.com/inertia_js_f31053abeb.svg",
      },
    ],
  },
]

const TechStacksTypeScript = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="TypeScriptTab">
      <div className={styles.globalTypeScriptTech}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContent}>
              <div>
                <Nav variant="pills" className={`TypeScriptTab`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlock">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default TechStacksTypeScript
