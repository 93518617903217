import { Link } from "gatsby"
import React from "react"
import * as styles from "./GainAdvantage.module.scss"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import star from "../../images/hire-typescript/star.svg"
const GainAdvantage = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.gainAdvantage}>
        <Container>
          <Row>
            <Col lg={6} md={6} xs={6} sm={6}>
              <div className={styles.gainContent}>
                <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
                <p
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.subTitle,
                  }}
                />
                <div className={styles.advantageBtns}>
                  <div className={`${styles.bannerBtnAdvantage}`}>
                    {strapiData?.buttons[0] && (
                      <Link
                        to={strapiData?.buttons[0]?.url}
                        className={styles.getBtnAdvantage}
                      >
                        {strapiData?.buttons[0]?.title}
                      </Link>
                    )}
                  </div>
                  <div className={`${styles.bannerBtnHireDEveloper}`}>
                    {strapiData?.buttons[1] && (
                      <Link
                        to="#typescriptform"
                        className={styles.getBtnHireDeveloper}
                      >
                        {strapiData?.buttons[1]?.title}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className={styles.allCardsItems}>
                <div className={styles.cards}>
                  {strapiData?.cards &&
                    strapiData?.cards?.map((item, index) => (
                      <div className={styles.talent} key={index}>
                        <img
                          className={styles.tick}
                          src={star}
                          alt={item?.title}
                        />
                        <div className={styles.text}>
                          <p>{item?.title}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default GainAdvantage
