// extracted by mini-css-extract-plugin
export var TypeScriptDevelopers = "TypeScriptDevelopere-module--TypeScriptDevelopers--61a77";
export var allSkills = "TypeScriptDevelopere-module--allSkills--efea0";
export var bottomRating = "TypeScriptDevelopere-module--bottomRating--b2ea8";
export var card = "TypeScriptDevelopere-module--card--6ef9b";
export var cardDesc = "TypeScriptDevelopere-module--cardDesc--806e4";
export var cardWrapper = "TypeScriptDevelopere-module--cardWrapper--862d3";
export var devContent = "TypeScriptDevelopere-module--devContent--00285";
export var developersData = "TypeScriptDevelopere-module--developersData--2a396";
export var experience = "TypeScriptDevelopere-module--experience--cb6c0";
export var expert = "TypeScriptDevelopere-module--expert--2672e";
export var heading = "TypeScriptDevelopere-module--heading--082f0";
export var mernIcon = "TypeScriptDevelopere-module--mernIcon--787b2";
export var review = "TypeScriptDevelopere-module--review--3d184";
export var skill = "TypeScriptDevelopere-module--skill--13067";
export var skillBtn = "TypeScriptDevelopere-module--skillBtn--2ab2d";
export var skills = "TypeScriptDevelopere-module--skills--d8486";
export var typeBtn = "TypeScriptDevelopere-module--typeBtn--ff1e3";
export var typedevBtn = "TypeScriptDevelopere-module--typedevBtn--24642";