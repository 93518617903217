// extracted by mini-css-extract-plugin
export var Golang = "Combinitation-module--Golang--44e1f";
export var bannerBtnGolangBook = "Combinitation-module--bannerBtnGolangBook--b0fb0";
export var btn_white2_border_book_Goolang_dev = "Combinitation-module--btn_white2_border_book_Goolang_dev--25736";
export var card1 = "Combinitation-module--card1--2b94e";
export var card2 = "Combinitation-module--card2--27c80";
export var card3 = "Combinitation-module--card3--0e5f9";
export var card4 = "Combinitation-module--card4--c9682";
export var card5 = "Combinitation-module--card5--75a08";
export var card6 = "Combinitation-module--card6--ca0f9";
export var cardWrapper = "Combinitation-module--cardWrapper--02f41";
export var description = "Combinitation-module--description--a7f9d";
export var heading = "Combinitation-module--heading--62cb9";
export var imgs = "Combinitation-module--imgs--1f98d";
export var imgs2 = "Combinitation-module--imgs2--0194e";
export var imgs3 = "Combinitation-module--imgs3--9c237";
export var imgs4 = "Combinitation-module--imgs4--f48b8";
export var imgs5 = "Combinitation-module--imgs5--e2ff8";
export var imgs6 = "Combinitation-module--imgs6--11532";
export var plus = "Combinitation-module--plus--266d0";
export var typeScriptImg = "Combinitation-module--typeScriptImg--f9bd8";
export var typescriptNextGen = "Combinitation-module--typescriptNextGen--95ee8";