// extracted by mini-css-extract-plugin
export var advantageBtns = "GainAdvantage-module--advantageBtns--f4509";
export var allCardsItems = "GainAdvantage-module--allCardsItems--712f8";
export var bannerBtnAdvantage = "GainAdvantage-module--bannerBtnAdvantage--3905a";
export var bannerBtnHireDEveloper = "GainAdvantage-module--bannerBtnHireDEveloper--b5725";
export var cards = "GainAdvantage-module--cards--c075c";
export var description = "GainAdvantage-module--description--18e84";
export var gainAdvantage = "GainAdvantage-module--gainAdvantage--94f5b";
export var gainContent = "GainAdvantage-module--gainContent--74b72";
export var getBtnAdvantage = "GainAdvantage-module--getBtnAdvantage--12713";
export var getBtnHireDeveloper = "GainAdvantage-module--getBtnHireDeveloper--42d87";
export var talent = "GainAdvantage-module--talent--273c4";
export var text = "GainAdvantage-module--text--05794";
export var tick = "GainAdvantage-module--tick--2f1dc";