// extracted by mini-css-extract-plugin
export var GainAdvantageMobile = "GainAdvantageMobile-module--GainAdvantageMobile--907c7";
export var advantageBtns = "GainAdvantageMobile-module--advantageBtns--c0629";
export var allCardsItemsMobile = "GainAdvantageMobile-module--allCardsItemsMobile--3a523";
export var bannerBtnAdvantage = "GainAdvantageMobile-module--bannerBtnAdvantage--3a0c6";
export var bannerBtnHireDEveloper = "GainAdvantageMobile-module--bannerBtnHireDEveloper--69f67";
export var cards = "GainAdvantageMobile-module--cards--41fc0";
export var description = "GainAdvantageMobile-module--description--dece6";
export var getBtnAdvantage = "GainAdvantageMobile-module--getBtnAdvantage--7dd95";
export var getBtnHireDeveloper = "GainAdvantageMobile-module--getBtnHireDeveloper--51da7";
export var mobileGainData = "GainAdvantageMobile-module--mobileGainData--ed918";
export var talent = "GainAdvantageMobile-module--talent--52fa7";
export var text = "GainAdvantageMobile-module--text--5ad3d";
export var tick = "GainAdvantageMobile-module--tick--e271d";