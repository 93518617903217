import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import safdar from "../../images/hire-typescript/safdar.svg"
import hamza from "../../images/hire-typescript/hamza.svg"
import nadeem from "../../images/hire-typescript/nadeem.svg"
import stars4 from "../../images/hire-typescript/4.5Stars.svg"
import fiveStars from "../../images/hire-typescript/5Stars.svg"
import * as styles from "./TypeScriptDevelopere.module.scss"
import mernIcon from "../../images/hireMernStack/armyIcon.svg"
const TypeScriptDevelopers = ({ strapiData }) => {
  const devSDetails = [
    {
      name: "Hamza Sajid",
      experience: "3 years of experience",
      desc: "Hamza has expertise in the development of seamless solutions. He is a dynamic team player who brings his unique perspective to projects.",
      rating: stars4,
      reviews: "<b>4.8</b> Reviews",
    },
    {
      name: "Safdar Abbas",
      experience: "8 years of experience",
      desc: "Safdar brings not only expertise but wide experience to your project. He has worked on 300+ solutions from teams around the globe.",
      rating: fiveStars,
      reviews: "<b>5.0</b> Stars Reviews",
    },
    {
      name: "Nadeem Aslam",
      experience: "6 years of experience",
      desc: "Nadeem creates flawless, seamless, and bug-free solutions. He is highly flexible and efficiently coordinates with his team members.",
      rating: stars4,
      reviews: "<b>4.8</b> Stars Reviews",
    },
  ]
  const dataDev1 = [
    {
      skill: "TypeScript",
    },
    {
      skill: "FlowJS",
    },
    {
      skill: "RedwoodJS",
    },
    {
      skill: "NuxtJS",
    },
    {
      skill: "TypeScript",
    },
    {
      skill: "FlowJS",
    },
    {
      skill: "RedwoodJS",
    },
    {
      skill: "NuxtJS",
    },
  ]
  const dataDev2 = [
    {
      skill: "TypeScript",
    },
    {
      skill: "FlowJS",
    },
    {
      skill: "MeteorJS",
    },
    {
      skill: "InertiaJSC",
    },
    {
      skill: "D3JS",
    },
    {
      skill: "NestJS",
    },
    {
      skill: "NuxtJS",
    },
  ]
  const dataDev3 = [
    {
      skill: "TypeScript",
    },
    {
      skill: "EtherJS",
    },
    {
      skill: "RedwoodJS",
    },
    {
      skill: "NuxtJS",
    },
    {
      skill: "BackboneJS",
    },
  ]
  return (
    <React.Fragment>
      <div className={styles.TypeScriptDevelopers}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <Row className={styles.cardWrapper}>
            {devSDetails &&
              devSDetails.map((e, i) => (
                <Col lg={4} md={6} sm={12} xs={12} key={i}>
                  <div className={styles.card}>
                    <div className={` d-flex ${styles.developersData}`}>
                      <div>
                        <img
                          src={
                            i === 0
                              ? hamza
                              : i === 1
                              ? safdar
                              : i === 2
                              ? nadeem
                              : ""
                          }
                          alt={e?.name}
                          decoding="async"
                          loading="lazy"
                        />
                      </div>
                      <div className={styles.devContent}>
                        <h3>{e?.name}</h3>
                        <p className={styles.experience}>{e?.experience}</p>
                      </div>
                    </div>
                    <div className={styles.allSkills}>
                      {i === 0
                        ? dataDev1 &&
                          dataDev1?.map((s, e) => (
                            <div className={styles.skills} key={e}>
                              <div className={`${styles.skill}`} data-index={e}>
                                {s?.skill}{" "}
                              </div>
                            </div>
                          ))
                        : i === 1
                        ? dataDev2 &&
                          dataDev2.map((sl, el) => (
                            <div className={styles.skills} key={el}>
                              <div
                                className={`${styles.skill}`}
                                data-index={el}
                              >
                                {sl?.skill}
                              </div>
                            </div>
                          ))
                        : i === 2
                        ? dataDev3 &&
                          dataDev3.map((al, ei) => (
                            <div className={styles.skills} key={ei}>
                              <div
                                className={`${styles.skill}`}
                                data-index={ei}
                              >
                                {" "}
                                {al?.skill}
                              </div>
                            </div>
                          ))
                        : ""}
                    </div>
                    <p className={styles.cardDesc}>{e?.desc}</p>
                    <div className={styles.typedevBtn}>
                      <Link to="#typescriptform">
                        <Button className={styles.typeBtn}>
                          <img
                            src={mernIcon}
                            alt="icon"
                            decoding="async"
                            loading="lazy"
                            className={styles.mernIcon}
                          />
                          {`Hire ${e?.name}`}
                        </Button>
                      </Link>
                    </div>
                    <div className={styles.bottomRating}>
                      <img
                        src={e?.rating}
                        alt="raiting"
                        decoding="async"
                        loading="lazy"
                      />
                      <div
                        className={styles.review}
                        dangerouslySetInnerHTML={{
                          __html: e?.reviews,
                        }}
                      />
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default TypeScriptDevelopers
