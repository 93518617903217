import { Link } from "gatsby"
import React from "react"
import * as styles from "./Combinitation.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import angular from "../../images/hire-typescript/angular.svg"
import electron from "../../images/hire-typescript/electron.svg"
import nodejs from "../../images/hire-typescript/nodeJs.svg"
import vue from "../../images/hire-typescript/vue.svg"
import graphql from "../../images/hire-typescript/graphql.svg"
import react from "../../images/hire-typescript/react.svg"
import js from "../../images/hire-typescript/javaScript.svg"
import typescript from "../../images/hire-typescript/typescript.svg"
import plus from "../../images/hire-typescript/plusType.svg"
const Combinitation = ({ strapiData }) => {
  const nextGenData = [
    {
      stcak1: angular,
      stack2: typescript,
      stack3: js,
      title: "Angular (TypeScript + JavaScript)",
      desc: "TypeScript is a primary language for Angular development, providing strong typing & enhanced tooling.",
    },
    {
      stcak1: vue,
      stack2: typescript,
      stack3: js,
      title: "Vue.js (TypeScript + JavaScript)",
      desc: "Vue.js has official TypeScript support, enabling developers to write Vue components in TypeScript.",
    },
    {
      stcak1: nodejs,
      stack2: typescript,
      stack3: js,
      title: "Node.js (TypeScript + JavaScript)",
      desc: "Our TypeScript developers use TypeScript for server-side development with Node.js, allowing better code organization & scalability.",
    },
    {
      stcak1: react,
      stack2: typescript,
      stack3: js,
      title: "React Native (TypeScript + JavaScript)",
      desc: "Our developers use TypeScript to develop mobile applications with React Native, offering a more robust development experience.",
    },
    {
      stcak1: electron,
      stack2: typescript,
      stack3: js,
      title: "Electron (TypeScript + JavaScript)",
      desc: "TypeScript is used to build cross-platform desktop applications using the Electron framework.",
    },
    {
      stcak1: graphql,
      stack2: typescript,
      stack3: js,
      title: "GraphSQL (TypeScript + JavaScript)",
      desc: "TypeScript can enhance GraphSQL server development offering type safety for mutations and queries.",
    },
  ]
  return (
    <div className={styles.typescriptNextGen}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className={`gap-30 justify-content-center ${styles.cardWrapper}`}>
          {nextGenData.map((e, i) => (
            <Col xl={6} md={6} xs={12} key={i} className={styles.Golang}>
              <div className={` ${styles[`card${i + 1}`]} `}>
                {i === 0 ? (
                  <div className={styles.imgs}>
                    <img
                      className={styles.typeScriptImg}
                      src={e?.stcak1}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                    <img
                      className={styles.typeScriptImg}
                      src={e?.stack2}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                    <img
                      className={styles.plus}
                      src={plus}
                      alt="plus"
                      decoding="async"
                      loading="lazy"
                    />
                    <img
                      className={styles.typeScriptImg}
                      src={e?.stack3}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                  </div>
                ) : i === 1 ? (
                  <div className={styles.imgs2}>
                    <img
                      className={styles.typeScriptImg}
                      src={e?.stcak1}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                    <img
                      className={styles.typeScriptImg}
                      src={e?.stack2}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                    <img
                      className={styles.plus}
                      src={plus}
                      alt="plus"
                      decoding="async"
                      loading="lazy"
                    />
                    <img
                      className={styles.typeScriptImg}
                      src={e?.stack3}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                  </div>
                ) : i === 2 ? (
                  <div className={styles.imgs3}>
                    <img
                      className={styles.typeScriptImg}
                      src={e?.stcak1}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                    <img
                      className={styles.typeScriptImg}
                      src={e?.stack2}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                    <img
                      className={styles.plus}
                      src={plus}
                      alt="plus"
                      decoding="async"
                      loading="lazy"
                    />
                    <img
                      className={styles.typeScriptImg}
                      src={e?.stack3}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                  </div>
                ) : i === 3 ? (
                  <div className={styles.imgs4}>
                    <img
                      className={styles.typeScriptImg}
                      src={e?.stcak1}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                    <img
                      className={styles.typeScriptImg}
                      src={e?.stack2}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                    <img
                      className={styles.plus}
                      src={plus}
                      alt="plus"
                      decoding="async"
                      loading="lazy"
                    />
                    <img
                      className={styles.typeScriptImg}
                      src={e?.stack3}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                  </div>
                ) : i === 4 ? (
                  <div className={styles.imgs5}>
                    <img
                      className={styles.typeScriptImg}
                      src={e?.stcak1}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                    <img
                      className={styles.typeScriptImg}
                      src={e?.stack2}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                    <img
                      className={styles.plus}
                      src={plus}
                      alt="plus"
                      decoding="async"
                      loading="lazy"
                    />
                    <img
                      className={styles.typeScriptImg}
                      src={e?.stack3}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                  </div>
                ) : i === 5 ? (
                  <div className={styles.imgs6}>
                    <img
                      className={styles.typeScriptImg}
                      src={e?.stcak1}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                    <img
                      className={styles.typeScriptImg}
                      src={e?.stack2}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                    <img
                      className={styles.plus}
                      src={plus}
                      alt="plus"
                      decoding="async"
                      loading="lazy"
                    />
                    <img
                      className={styles.typeScriptImg}
                      src={e?.stack3}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                  </div>
                ) : (
                  ""
                )}

                <h3>{e?.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: e?.desc,
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
        <div className={`${styles.bannerBtnGolangBook}`}>
          {strapiData?.buttons[0] && (
            <Link
              to="#typescriptform"
              className={styles.btn_white2_border_book_Goolang_dev}
            >
              {strapiData?.buttons[0]?.title}
            </Link>
          )}
        </div>
      </Container>
    </div>
  )
}

export default Combinitation
