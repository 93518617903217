import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Success.module.scss"

const data = [
  {
    title: "Hourly Hiring",
    img: "https://invozone-backend.s3.amazonaws.com/lottie_72d7a2625b.json",
    tech: [
      {
        name: "Start The Project",
        dec: "Within 24-48 Hours",
      },
      {
        name: "Total Duration",
        dec: "8 Hours Per Day",
      },
      {
        name: "Minimum Time Frame",
        dec: "30 Days",
      },
      {
        name: "Billing",
        dec: "On a Monthly Basis",
      },
    ],
  },
  {
    title: "On a Monthly Basis",
    img: "https://invozone-backend.s3.amazonaws.com/lottie_72d7a2625b.json",
    tech: [
      {
        name: "Start The Project",
        dec: "Within 48 Hours",
      },
      {
        name: "Total Duration",
        dec: "8 Hours Per Day",
      },
      {
        name: "Minimum Time Frame",
        dec: "30 Days",
      },
      {
        name: "Billing",
        dec: "On a Monthly Basis",
      },
    ],
  },
  {
    title: "Part-Time Hiring",
    img: "https://invozone-backend.s3.amazonaws.com/lottie_72d7a2625b.json",
    tech: [
      {
        name: "Start The Project",
        dec: "Within 24-48 Hours",
      },
      {
        name: "Total Duration",
        dec: "30 Days",
      },
      {
        name: "Minimum Time Frame",
        dec: "30 Days",
      },
      {
        name: "Billing",
        dec: "On a Monthly Basis",
      },
    ],
  },
]

const Success = ({ strapiData }) => {
  return (
    <div className={styles.customizedSuccessTypeScript}>
      <Container>
        <div>
          <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
          <p
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
        </div>
        <div>
          {strapiData?.cards?.map((el, i) => (
            <div key={i}>
              <p>{el?.title}</p>
            </div>
          ))}
        </div>
        <Row>
          {data &&
            data?.map((e, i) => (
              <Col lg={4} key={i}>
                <div className={styles.card}>
                  <div className={styles.hiring}>
                    <lottie-player
                      autoplay
                      loop
                      src={e?.img}
                      style={{ height: "60px", width: "60px", margin: "0px" }}
                    />
                    <h3>{e?.title}</h3>
                  </div>
                  <div className={styles.cardBg}></div>
                  <div className={styles.time}>
                    {e?.tech &&
                      e?.tech?.map((el, i) => (
                        <div className={styles.project} key={i}>
                          <span>{el?.name}</span>
                          <p className={styles.dataDes}>{el?.dec}</p>
                        </div>
                      ))}
                    <Link className={styles.request} to="#typescriptform">
                      Hire TypeScript Developers
                    </Link>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  )
}

export default Success
