// extracted by mini-css-extract-plugin
export var cardBg = "TechStacksTypeScript-module--cardBg--0b003";
export var cards = "TechStacksTypeScript-module--cards--46541";
export var cardsContent = "TechStacksTypeScript-module--cardsContent--44455";
export var description = "TechStacksTypeScript-module--description--9ff32";
export var globalTypeScriptTech = "TechStacksTypeScript-module--globalTypeScriptTech--d645a";
export var heading = "TechStacksTypeScript-module--heading--2fcce";
export var iconssCard = "TechStacksTypeScript-module--iconssCard--2abe8";
export var nav = "TechStacksTypeScript-module--nav--c42da";
export var navItem = "TechStacksTypeScript-module--nav-item--28748";
export var navbarBlock = "TechStacksTypeScript-module--navbarBlock--14557";
export var tabData = "TechStacksTypeScript-module--tabData--9fd0b";
export var tech = "TechStacksTypeScript-module--tech--7a8f3";
export var techIcon = "TechStacksTypeScript-module--techIcon--907e2";
export var techImg = "TechStacksTypeScript-module--techImg--84215";
export var technologyIcon = "TechStacksTypeScript-module--technologyIcon--72c83";