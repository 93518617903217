import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import "./TechStacksMobile.scss"
import * as styles from "./TechStacksMobile.module.scss"

const TechStacksMobile = ({ strapiData }) => {
  const data = [
    {
      title: "Framework",
      tech: [
        {
          name: "NextJs",
          icon: "https://invozone-backend.s3.amazonaws.com/next_js_6c43cde822.svg",
        },
        {
          name: "AlpineJS",
          icon: "https://invozone-backend.s3.amazonaws.com/Alpine_js_3a3dc8e1a2.svg",
        },
        {
          name: "NestJS",
          icon: "https://invozone-backend.s3.amazonaws.com/nest_js_9a21773270.svg",
        },
        {
          name: "MeteorJS",
          icon: "https://invozone-backend.s3.amazonaws.com/meteor_js_8c31ccbb88.svg",
        },
        {
          name: "NuxtJS",
          icon: "https://invozone-backend.s3.amazonaws.com/nuxt_js_21bb7ea476.svg",
        },
        {
          name: "StimulusJS",
          icon: "https://invozone-backend.s3.amazonaws.com/stimulus_2eff872d85.svg",
        },
        {
          name: "RedwoodJS",
          icon: "https://invozone-backend.s3.amazonaws.com/redwood_js_aa7be4f8b7.svg",
        },
      ],
    },
    {
      title: "Libraries",
      tech: [
        {
          name: "EtherJS",
          icon: "https://invozone-backend.s3.amazonaws.com/ethers_js_df78406020.svg",
        },
        {
          name: "BackboneJS",
          icon: "https://invozone-backend.s3.amazonaws.com/backbone_js_e97e7a64e0.svg",
        },
        {
          name: "FlowJS",
          icon: "https://invozone-backend.s3.amazonaws.com/flow_js_7053028d2b.svg",
        },
        {
          name: "D3JS",
          icon: "https://invozone-backend.s3.amazonaws.com/d3_js_fabadda7ff.svg",
        },
        {
          name: "CallKit",
          icon: "https://invozone-backend.s3.amazonaws.com/callkit_420fdf1568.svg",
        },
        {
          name: "Web3JS",
          icon: "https://invozone-backend.s3.amazonaws.com/web3_js_5343b9f853.svg",
        },
        {
          name: "InertiaJS",
          icon: "https://invozone-backend.s3.amazonaws.com/inertia_js_f31053abeb.svg",
        },
      ],
    },
  ]

  const [activeAccordion, setActiveAccordion] = useState(`${data[0]?.title}`)
  // const [activeAccordionIndex, setActiveAccordionIndex] = useState(0)
  return (
    <div className="TechTypeScriptMobile">
      <div className={styles.globalIndusTypeScriptTech}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Accordion defaultActiveKey={`${data[0]?.title}`}>
            {data &&
              data?.map((e, i) => (
                <Card className="" key={i}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={`${e?.title}`}
                    className={`TypeScriptAccord ${
                      e?.title === activeAccordion ? "active-accordion" : ""
                    } ${
                      (i === 1 || i === 3) && e?.title !== activeAccordion
                        ? "second-accordion"
                        : (i === 0 || i === 2) && e?.title !== activeAccordion
                        ? "first-accordion"
                        : ""
                    }`}
                    onClick={() => setActiveAccordion(e?.title)}
                    style={
                      i === 1 && e?.title === activeAccordion
                        ? { marginTop: "24px" }
                        : {}
                    }
                  >
                    {e?.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${e?.title}`}>
                    <Card.Body className={`TypeScriptcardBody`}>
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
          </Accordion>
        </Container>
      </div>
    </div>
  )
}

export default TechStacksMobile
